<template>
	<div id="nav">
		<component :is="layout"> <router-view></router-view> </component>
	</div>
</template>
<script>
import emptyLayout from "@/layouts/emptyLayout";
import mainLayout from "@/layouts/mainLayout";
export default {
	mounted() {
		let app2js = document.createElement("script");
		app2js.setAttribute("src", "js/app2.js");
		document.head.appendChild(app2js);
	},
	computed: {
		layout() {
			return (this.$route.meta.layout || "empty") + "-layout";
		},
	},
	components: {
		emptyLayout,
		mainLayout,
	},
};
</script>


<style lang="scss">
@import "/css/layout.css";
@import "/css/about.css";
@import "/css/actions.css";
@import "/css/cardProduct.css";
@import "/css/contacts.css";
@import "/css/deliviryAndInstallation.css";
@import "/css/garants.css";
@import "/css/mapOfSite.css";
@import "/css/paymentOrder.css";
@import "/css/recentlyViewedDirectory.css";
</style>
<IfModule mod_rewrite.c>
  RewriteEngine On
  RewriteBase /
  RewriteRule ^index\.html$ - [L]
  RewriteCond %{REQUEST_FILENAME} !-f
  RewriteCond %{REQUEST_FILENAME} !-d
  RewriteRule . /index.html [L]
</IfModule>