import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	}, {
		path: '/login',
		name: 'login',
		meta: { layout: 'empty' },
		component: () => import('../views/login')
	}, {
		path: '/about',
		name: 'about',
		meta: { layout: 'main' },
		component: () => import('../views/about')
	}, {
		path: '/actions',
		name: 'actions',
		meta: { layout: 'main' },
		component: () => import('../views/actions')
	}, {
		path: '/cardProduct',
		name: 'cardProduct',
		meta: { layout: 'main' },
		component: () => import('../views/cardProduct')
	}, {
		path: '/contacts',
		name: 'contacts',
		meta: { layout: 'main' },
		component: () => import('../views/contacts')
	}, {
		path: '/deliveryAndInstallation',
		name: 'deliveryAndInstallation',
		meta: { layout: 'main' },
		component: () => import('../views/deliveryAndInstallation')
	}, {
		path: '/garants',
		name: 'garants',
		meta: { layout: 'main' },
		component: () => import('../views/garants')
	}, {
		path: '/mapOfSite',
		name: 'mapOfSite',
		meta: { layout: 'main' },
		component: () => import('../views/mapOfSite')
	}, {
		path: '/paymentOrder',
		name: 'paymentOrder',
		meta: { layout: 'main' },
		component: () => import('../views/paymentOrder')
	}, {
		path: '/recentlyViewedDirectoty',
		name: 'recentlyViewedDirectoty',
		meta: { layout: 'main' },
		component: () => import('../views/recentlyViewedDirectoty')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
