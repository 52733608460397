<template>
	<header class="header">
		<div class="container">
			<img class="logo" src="images/logo.svg" alt="" />
			<nav class="header__menu-nav">
				<button class="btn-close" id="btn-close">
					<img src="images/btn-close.svg" alt="" />
				</button>
				<ul class="header__menu-ul">
					<router-link
						to="/mapOfSite"
						active-class="active"
						tag="li"
						exact
						><a href="">Каталог</a></router-link
					>
					<li class="header__li-list">
						Сервис<img
							class="header__li-list-img"
							src="images/triagle.svg"
							alt=""
						/>
						<ul class="header__li-list-ul">
							<router-link
								v-for="link in links2"
								:key="link.url"
								tag="li"
								:to="link.url"
								active-class="active"
								exact
								><a href="">{{ link.title }}</a></router-link
							>
						</ul>
					</li>
					<router-link
						v-for="link in links1"
						:key="link.url"
						tag="li"
						active-class="active"
						:to="link.url"
						exact
						><a href="">{{ link.title }}</a></router-link
					>
				</ul>
				<button class="btn-help-to-choose">
					<div>Помочь выбрать</div>
					<img
						class="btn-help-to-choose__arrow-img"
						src="images/Arrow 9.svg"
						alt=""
					/><img
						class="btn-help-to-choose__circle-img"
						src="images/btn-circle-img.svg"
						alt=""
					/>
				</button>
			</nav>
			<div class="help-nav">
				Помочь выбрать
				<div class="help-nav__circle"></div>
			</div>
			<button class="help-nav-2" id="help-nav-2">
				Меню
				<div class="help-nav__circle"></div>
			</button>
		</div>
	</header>
</template>

<script>
export default {
	data: () => ({
		links1: [
			{ title: "Акции", url: "/actions" },
			{ title: "О производителе", url: "/about" },
			{ title: "Контакты", url: "/contacts" },
		],
		links2: [
			{ title: "Как выбрать двери", url: "/cardProduct" },
			{ title: "Доставка и установка", url: "/deliveryAndInstallation" },
			{ title: "Порядок оплаты", url: "/paymentOrder" },
			{ title: "Гарантии", url: "/garants" },
		],
	}),
};
</script>