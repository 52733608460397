<template>
	<headermv />
	<main class="main">
		<div class="container">
			<router-view> </router-view>
		</div>
	</main>
	<footermv />
</template>

<script>
import footermv from "@/components/app/footer.vue";
import headermv from "@/components/app/header.vue";
export default {
	name: "main-layout",
	components: {
		footermv,
		headermv,
	},
};
</script>