<template>
	<footer class="footer">
		<div class="container">
			<div class="block-1">
				<img class="logo-2" src="images/logo-2.svg" alt="" />
				<nav class="footer__menu-nav">
					<ul class="footer__menu-ul">
						<li><a href="">Каталог</a></li>
						<li><a href="">Как выбрать двери</a></li>
						<li><a href="">Акции</a></li>
						<li><a href="">О производителе</a></li>
						<li><a href="">Карта сайта</a></li>
					</ul>
				</nav>
				<div class="phone-cont">
					<div class="phone">8 800 666 666 66</div>
					<div class="p">Бесплатный звонок</div>
				</div>
			</div>
			<div class="block-2">
				<div class="rights">© Expert 2021 все права защищены</div>
				<div class="center-footer">
					<a href=""> Обработка персональных данных</a
					><a href=""> Политика конфиденциальности</a>
				</div>
				<a class="inst"
					><span>Instagram</span
					><svg
						width="8"
						height="8"
						viewBox="0 0 8 8"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M7.5 0.999999C7.5 0.723857 7.27614 0.5 7 0.499999L2.5 0.5C2.22386 0.5 2 0.723857 2 1C2 1.27614 2.22386 1.5 2.5 1.5L6.5 1.5L6.5 5.5C6.5 5.77614 6.72386 6 7 6C7.27614 6 7.5 5.77614 7.5 5.5L7.5 0.999999ZM1.35355 7.35355L7.35355 1.35355L6.64645 0.646446L0.646447 6.64645L1.35355 7.35355Z"
							fill="#F74646"
						/></svg
				></a>
			</div>
		</div>
	</footer>
</template>
